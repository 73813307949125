import { API_NAMES, initApi } from '../../../common/utils/HttpApiHelper';
import { BASIC_INITIAL_STATE } from '../../../common/utils/HttpHelper';

const INITIAL_MEMBER_REGISTRATION_OFFERINGS_STATE = {
  ...BASIC_INITIAL_STATE
};

const getMemberRegistrationOfferingsData = (personId, orgUnitId, offeringInstanceCouponId, state, setState) => {
  if (personId && orgUnitId) {
    const api = initApi(API_NAMES.OMR, state, setState);
    const personIdForUrl = encodeURIComponent(personId);
    const orgUnitIdForUrl = encodeURIComponent(orgUnitId);
    const offeringInstanceCouponIdForUrl = offeringInstanceCouponId ? encodeURIComponent(offeringInstanceCouponId) : '';
    const url = `/MemberRegistration/person/${personIdForUrl}/orgUnit/${orgUnitIdForUrl}?offeringInstanceCouponId=${offeringInstanceCouponIdForUrl}`;

    return api?.executeArray ? api.executeArray(url, 'GET') : null;
  }
};

const postMemberRegistrationOfferingsData = (personId, orgUnitId, routeCode, offeringInstanceCouponId, competitionGenderTypeId, offeringsArray, state, setState) => {
  if (personId && orgUnitId) {
    const api = initApi(API_NAMES.OMR, state, setState);
    const personIdForUrl = encodeURIComponent(personId);
    const orgUnitIdForUrl = encodeURIComponent(orgUnitId);
    const routeCodeForUrl = routeCode ? encodeURIComponent(routeCode): '';
    const offeringInstanceCouponIdForUrl = offeringInstanceCouponId ? encodeURIComponent(offeringInstanceCouponId) : '';
    const competitionGenderTypeIdForUrl = competitionGenderTypeId ? encodeURIComponent(competitionGenderTypeId) : '';
    const url = `/MemberRegistration/person/${personIdForUrl}/orgUnit/${orgUnitIdForUrl}?routeCode=${routeCodeForUrl}&offeringInstanceCouponId=${offeringInstanceCouponIdForUrl}&competitionGenderTypeId=${competitionGenderTypeIdForUrl}`;

    return api?.executeArray ? api.executeArray(url, 'POST', offeringsArray) : null;
  }
};

const MemberRegistrationOfferingsData = {
  INITIAL_MEMBER_REGISTRATION_OFFERINGS_STATE,
  getMemberRegistrationOfferingsData,
  postMemberRegistrationOfferingsData
};

export default MemberRegistrationOfferingsData;