import { useState, useEffect } from 'react';
import { useNavigate, useSearchParams } from '../../../common/wrappers/ReactRouterDom';

import competitionGenderTypeSelectionValidate from './RenewalCompetitionGenderTypeFormValidation';

import UseOmrData from '../../state/omr/UseOmrData';
import useOmrCookieData from '../../state/omrCookie/UseOmrCookieData';
import usePersonData from '../../state/landingPages/person/UsePersonData';
import useChildrenData from '../../state/landingPages/children/UseChildrenData';
import useLandingPageData from '../../state/landingPages/UseLandingPageData';
import useOmrBackButtonData from '../../state/omrBackButton/UseOmrBackButtonData';
import useMemberRegistrationData from '../../state/memberRegistration/UseMemberRegistrationData';
import useMemberRegistrationOfferingsData from '../../state/memberRegistrationOfferings/UseMemberRegistrationOfferingsData';

import useNavRoutes from '../../../common/state/security/UseNavRoutes';
import useRouteCodeValueData from '../../../common/state/routeCode/UseRouteCodeValueData';
import useOrgUnitData from '../../../common/state/orgUnit/UseOrgUnitData';
import useGlobalRoutesData from '../../../common/state/security/UseGlobalRoutesData';
import useSecurityData from '../../../common/state/security/UseSecurityData';
import useEnvironmentVariableData from '../../../common/state/environmentVariable/UseEnvironmentVariableData';

import Constants from '../../../common/utils/Constants';
import useDidMountEffect from '../../../common/utils/UseDidMountEffect';
import { navigateToCrossUiRoute } from '../../../common/utils/FormatCrossUiRoute';

const INITIAL_OFFERING_MODAL_STATE = {
  modalTitle: 'Manage Memberships',
  displayPopUp: false,
  member: {}
};

const INITIAL_RENEW_CONFIRMATION_MODAL_STATE = {
  displayPopUp: false,
  modalTitle: 'Are You Sure You Would Like to Update Information for & Register the Selected Family Member?',
  membership: {},
};

const INITIAL_STATE = {
  errorMessage: '',
  selectedOfferingCheckArray: [],
  allMembershipArray: [],
  selectedOfferingTypeIds: [],
  offeringRestrictions: []
};

const INITIAL_NEW_ROLE_CONFIRMATION_MODAL_STATE = {
  displayPopUp: false,
  modalTitle: 'The Selected Memberships Have Been Added to the Shopping Cart'
};

const INITIAL_COMPETITION_GENDER_TYPE_SELECTION_MODAL_STATE = {
  displayPopUp: false,
  modalTitle: 'Select Competition Category (Required When Adding an Athlete Membership)'
};

const INITIAL_COMPETITION_GENDER_TYPE_SELECTION_MODAL_FORM_STATE = {
  competitionGenderTypeId: Constants.DEFAULT_ID,
  competitionGenderTypeName: '',
};

const useMyFamilyWelcomeMessage = () => {
  const navigate = useNavigate();
  const { navRoutes } = useNavRoutes();
  const { memberRegistrationState, getMemberRegistration } = useMemberRegistrationData();
  const { memberRegistrationOfferingsState, setMemberRegistrationOfferingsState, getMemberRegistrationOfferings, postMemberRegistrationOfferings, resetMemberRegistrationOfferingsState } = useMemberRegistrationOfferingsData();
  const { omrCookieState, getOmrCookieState } = useOmrCookieData();
  const { routeCodeValueState } = useRouteCodeValueData();
  const { orgUnitState, getBaseOrgUnitByOrgUnitId } = useOrgUnitData();
  const { isSaved } = UseOmrData();
  const { getGlobalRoute } = useGlobalRoutesData();
  const { securityState } = useSecurityData();
  const { personState, getPersonByMemberId } = usePersonData();
  const { childrenState, getChildren } = useChildrenData();
  const { landingPageState, createLandingPageState, setAnonymous } = useLandingPageData();
  const { resetOmrBackButtonState } = useOmrBackButtonData();
  const { UNKNOWN_COMPETITION_GENDER_ID } = useEnvironmentVariableData();
  const [offeringModalState, setOfferingModalState] = useState(INITIAL_OFFERING_MODAL_STATE);
  const [renewModalState, setRenewModalState] = useState(INITIAL_RENEW_CONFIRMATION_MODAL_STATE);
  const [newRoleConfirmationModalState, setNewRoleConfirmationModalState] = useState(INITIAL_NEW_ROLE_CONFIRMATION_MODAL_STATE);
  const [competitionGenderTypeSelectionModalState, setCompetitionGenderTypeSelectionModalState] = useState(INITIAL_COMPETITION_GENDER_TYPE_SELECTION_MODAL_STATE);
  const [competitionGenderTypeSelectionModalFormState, setCompetitionGenderTypeSelectionModalFormState] = useState(INITIAL_COMPETITION_GENDER_TYPE_SELECTION_MODAL_FORM_STATE);
  const [competitionGenderTypeSelectionModalErrorState, setCompetitionGenderTypeSelectionModalErrorState] = useState({});
  const [state, setState] = useState(INITIAL_STATE);
  const [searchParams,] = useSearchParams();
  const isLoading = orgUnitState?.isObjLoaded !== true;
  const lsc = `${orgUnitState?.objData?.parentOrgUnitName || ''} (${orgUnitState?.objData?.parentOrgUnitCode || ''})`;
  const club = `${orgUnitState?.objData?.orgUnitName || ''} (${orgUnitState?.objData?.orgUnitCode || ''})`;
  const lscContact = `${orgUnitState?.objData?.parentContactFirstName ? `${orgUnitState?.objData?.parentContactFirstName} ` : ''}${orgUnitState?.objData?.parentContactLastName ? `${orgUnitState?.objData?.parentContactLastName} ` : ''}${orgUnitState?.objData?.parentContactEmail ? `(${orgUnitState?.objData?.parentContactEmail})` : ''}`;

  const onSelectClicked = (e, member) => {
    if (e && e.preventDefault) {
      e.preventDefault();
    }
    setOfferingModalState({ ...offeringModalState, member: member, displayPopUp: true });
  };

  const onOfferingModalCanceled = (e) => {
    if (e && e.preventDefault) {
      e.preventDefault();
    }
    setOfferingModalState(INITIAL_OFFERING_MODAL_STATE);
    setState(INITIAL_STATE);
    resetMemberRegistrationOfferingsState();
  };

  const onShowRenewalConfirmationModalClicked = (e, membership) => {
    if (e?.preventDefault) {
      e.preventDefault();
    }

    setRenewModalState({ ...renewModalState, displayPopUp: true, membership: membership });
  };

  const onRenewConfirmationClicked = (e) => {
    if (e?.preventDefault) {
      e.preventDefault();
    }

    onGoToOmrWithPersonId(e, renewModalState.membership);
    onRenewConfirmationModalCanceled();
  };

  const onGoToOmrWithPersonId = (e, membership) => {
    if (e && e.preventDefault) {
      e.preventDefault();
    }

    navigate(navRoutes.ENTRY?.route, { state: { membership } });
  };

  const onRenewConfirmationModalCanceled = (e) => {
    if (e?.preventDefault) {
      e.preventDefault();
    }

    setRenewModalState(INITIAL_RENEW_CONFIRMATION_MODAL_STATE);
  };

  const validate = () => {
    if (state.selectedOfferingTypeIds?.length === 0) {
      return 'At least one membership selection is required';
    }

    return '';
  };

  const onSubmit = () => {
    const errorMessage = validate();
    if (errorMessage) {
      setState({ ...state, errorMessage });
    } else {
      let showCompetitionCategoryPopup = false;
      for (let i = 0; i < state.selectedOfferingTypeIds.length; i++) {
        let selectedOfferingObj = memberRegistrationOfferingsState?.arrayData?.offeringTypeRegistrations.find(x => x.offeringInstanceId === state.selectedOfferingTypeIds[i]);

        if (selectedOfferingObj?.isAthleteOffering === true && offeringModalState?.member?.competitionGenderTypeId === UNKNOWN_COMPETITION_GENDER_ID) {
          showCompetitionCategoryPopup = true;
        }
      }

      if (showCompetitionCategoryPopup === true) {
        setCompetitionGenderTypeSelectionModalState({
          ...competitionGenderTypeSelectionModalState,
          displayPopUp: true
        })
      }

      else {
        const offeringsArray = []
        for (let i = 0; i < state.selectedOfferingTypeIds.length; i++) {
          let selectedOfferingObj = memberRegistrationOfferingsState?.arrayData?.offeringTypeRegistrations.find(x => x.offeringInstanceId === state.selectedOfferingTypeIds[i]);
          offeringsArray.push(selectedOfferingObj);
        }

        const postMemberRegistrationOfferingsPromise = postMemberRegistrationOfferings(offeringModalState.member?.personId, routeCodeValueState?.orgUnitId, routeCodeValueState?.routeCodeValue, routeCodeValueState?.offeringInstanceCouponId, '', offeringsArray);
        if (postMemberRegistrationOfferingsPromise ?? false) {
          postMemberRegistrationOfferingsPromise.then((newState) => {
            setMemberRegistrationOfferingsState({ ...memberRegistrationOfferingsState, newState, isSaved: true });
          }
          )
        }

      }
    }
  };

  const onAllMembershipCheck = (checked, offering, offeringInstanceId, offeringId) => {
    const newArray = [...state?.selectedOfferingTypeIds];
    const offeringsCheckArray = [...state?.selectedOfferingCheckArray];
    const restrictionArray = [...state.offeringRestrictions];
    let offeringObj = {
      offeringId: offeringId,
      offeringInstanceId: offeringInstanceId
    }

    if (checked === true) {
      //add to state array
      const index = newArray.indexOf(offeringInstanceId);
      if (index < 0) {
        newArray.push(offeringInstanceId);
        offeringsCheckArray.push({ ...offeringObj });
        if (Array.isArray(offering?.offeringRestriction) === true && offering?.offeringRestriction.length > 0) {
          offering?.offeringRestriction.forEach(restriction => {
            if (restriction.offeringId > 0) {
              restrictionArray.push(restriction);
            }
          });
        }
      }
    } else {
      //remove from state array
      const index = newArray.indexOf(offeringInstanceId);
      if (index >= 0) {
        newArray.splice(index, 1);
        if (Array.isArray(offering?.offeringRestriction) === true && offering?.offeringRestriction?.length > 0) {
          const offeringIndex = offeringsCheckArray.findIndex(x => x.offeringInstanceId === offeringInstanceId);
          const parentId = offering?.offeringRestriction.find(x => x.offeringParentId > 0).offeringParentId || 0;
          const restrictionArrayToRemove = restrictionArray?.filter(x => x.offeringParentId === parentId);

          if (offeringIndex >= 0) {
            offeringsCheckArray.splice(offeringIndex, 1)
          }
          if (Array.isArray(restrictionArrayToRemove) === true && restrictionArrayToRemove?.length > 0) {
            restrictionArrayToRemove.forEach(restriction => {
              const restrictionIndex = restrictionArray.findIndex(x => x.offeringParentId === parentId);
              if (restrictionIndex >= 0) {
                restrictionArray.splice(restrictionIndex, 1)
              }
            })
          }
        }
      };
    }
    setState({
      ...state,
      selectedOfferingTypeIds: newArray,
      selectedOfferingCheckArray: offeringsCheckArray,
      offeringRestrictions: restrictionArray
    });
  };

  const onCompetitionGenderTypeSelectionContinueClicked = async (e) => {
    if (e && e.preventDefault) {
      e.preventDefault();
    }
    const competitionGenderTypeSelectionErrors = await competitionGenderTypeSelectionValidate(competitionGenderTypeSelectionModalFormState) || {};
    if (Object.keys(competitionGenderTypeSelectionErrors).length === 0) {
      //save changes
      const offeringsArray = []
      for (let i = 0; i < state.selectedOfferingTypeIds.length; i++) {
        let selectedOfferingObj = memberRegistrationOfferingsState?.arrayData?.offeringTypeRegistrations.find(x => x.offeringInstanceId === state.selectedOfferingTypeIds[i]);
        offeringsArray.push(selectedOfferingObj);
      }

      const postMemberRegistrationOfferingsPromise = postMemberRegistrationOfferings(offeringModalState.member?.personId, routeCodeValueState?.orgUnitId, routeCodeValueState?.routeCodeValue, routeCodeValueState?.offeringInstanceCouponId, competitionGenderTypeSelectionModalFormState?.competitionGenderTypeId, offeringsArray);

      if (postMemberRegistrationOfferingsPromise ?? false) {
        postMemberRegistrationOfferingsPromise.then((newState) => {
          setMemberRegistrationOfferingsState({ ...memberRegistrationOfferingsState, newState, isSaved: true });
        }
        )
      }

      //close dropdown
      onCompetitionGenderTypeSelectionModalCanceled();
    }
    else {
      setCompetitionGenderTypeSelectionModalErrorState(competitionGenderTypeSelectionErrors);
    }
  };

  const onCompetitionGenderTypeSelectionModalCanceled = (e) => {
    if (e && e.preventDefault) {
      e.preventDefault();
    }

    setCompetitionGenderTypeSelectionModalState(INITIAL_COMPETITION_GENDER_TYPE_SELECTION_MODAL_STATE);
    setCompetitionGenderTypeSelectionModalFormState(INITIAL_COMPETITION_GENDER_TYPE_SELECTION_MODAL_FORM_STATE);
    setCompetitionGenderTypeSelectionModalErrorState({});
  };

  const onNewRoleConfirmationContinueClicked = (e) => {
    if (e && e.preventDefault) {
      e.preventDefault();
    }

    setNewRoleConfirmationModalState(INITIAL_NEW_ROLE_CONFIRMATION_MODAL_STATE);
    onOfferingModalCanceled();
    //Navigate to cart
    const paymentsRoute = getGlobalRoute('PAYMENTS_SHOPPING_CART');
    if (paymentsRoute && paymentsRoute.route) {
      navigateToCrossUiRoute(paymentsRoute.uIProjectName, paymentsRoute.route, navigate);
    }
  };

  const onNewRoleConfirmationModalCanceled = (e) => {
    if (e && e.preventDefault) {
      e.preventDefault();
    }

    setNewRoleConfirmationModalState(INITIAL_NEW_ROLE_CONFIRMATION_MODAL_STATE);
    onOfferingModalCanceled();
  };

  useEffect(() => {
    if (!personState?.objData?.personId
      && securityState?.isObjLoaded === true) {
      if (!securityState?.userInfo?.memberId || searchParams.get('flowId')) {
        setAnonymous();
      } else if (securityState?.userInfo?.memberId !== 'Unregistered') {
        //remove memberId search param, if there is one
        const memberId = searchParams.get('memberId');
        if (memberId) {
          const url = window.location.origin + window.location.pathname;
          window.location.search = '';
          window.location.href = url;
        }
        getPersonByMemberId(securityState?.userInfo?.memberId);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [securityState]);

  useEffect(() => {
    resetOmrBackButtonState();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (personState?.objData?.personId) {
      getMemberRegistration(personState?.objData?.personId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [personState?.objData?.personId, securityState]);

  useEffect(() => {
    if (personState?.objData?.personId) {
      getChildren(personState?.objData?.personId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [personState?.objData?.personId, securityState]);

  useEffect(() => {
    if (childrenState.isArrayLoaded === true) {
      createLandingPageState();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [childrenState.isArrayLoaded, securityState]);

  useEffect(() => {
    if (omrCookieState.isObjLoaded !== true) {
      getOmrCookieState();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [omrCookieState]);

  useEffect(() => {
    if (routeCodeValueState?.isObjLoaded === true && orgUnitState.isObjLoaded !== true && routeCodeValueState?.orgUnitId) {
      getBaseOrgUnitByOrgUnitId(routeCodeValueState.orgUnitId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [routeCodeValueState, orgUnitState]);

  // do this after the save state completes
  useDidMountEffect(() => {
    if (isSaved === true) {
      navigate(navRoutes.ENTRY.route);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSaved]);

  useEffect(() => {
    if (offeringModalState.displayPopUp === true) {
      const orgUnitId = orgUnitState.objData.orgUnitId;
      const personId = offeringModalState?.member?.personId;
      const offeringInstanceCouponId = routeCodeValueState?.offeringInstanceCouponId;

      getMemberRegistrationOfferings(personId, orgUnitId, offeringInstanceCouponId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [offeringModalState]);

  useEffect(() => {
    if (memberRegistrationOfferingsState.isSaved === true) {
      onOfferingModalCanceled();
      setNewRoleConfirmationModalState({ ...newRoleConfirmationModalState, displayPopUp: true });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [memberRegistrationOfferingsState.isSaved])

  return {
    isLoading,
    lsc,
    club,
    lscContact,
    memberRegistrationState,
    offeringModalState,
    state,
    showNoMembershipsAvailable: memberRegistrationOfferingsState?.isArrayLoaded === true && memberRegistrationOfferingsState?.arrayData.length === 0,
    memberRegistrationOfferingsState,
    restrictions: state?.offeringRestrictions,
    routeCodeValueState,
    onAllMembershipCheck,
    onOfferingModalCanceled,
    onAddNewMembershipClicked: onSubmit,
    onSelectClicked,
    renewModalState,
    onShowRenewalConfirmationModalClicked,
    onRenewConfirmationClicked,
    onRenewConfirmationModalCanceled,
    newRoleConfirmationModalState,
    onNewRoleConfirmationModalCanceled,
    onNewRoleConfirmationContinueClicked,
    stateStatus: landingPageState.stateStatus,
    competitionGenderTypeSelectionModalState,
    setCompetitionGenderTypeSelectionModalFormState,
    competitionGenderTypeSelectionModalFormState,
    competitionGenderTypeSelectionModalErrorState,
    onCompetitionGenderTypeSelectionContinueClicked,
    onCompetitionGenderTypeSelectionModalCanceled
  };
};

export default useMyFamilyWelcomeMessage;