import React, { Fragment } from 'react';

import Checkbox from '../../../../common/components/checkboxes/Checkbox';
import { formatDate } from '../../../../common/utils/DateFunctions';

import { VisuallyHidden } from "@reach/visually-hidden";

import global from '../../../../common/components/GlobalStyle.module.css';
import style from '../../Omr.module.css';

const LargeOfferingsGrid = ({ state, selectedOfferingTypeIds, restrictions, onChanged, onDetailsToggle, expandedId }) => {
  return (
    <Fragment>
      <div className='row usas-extra-bottom-margin'>
        <div className='col-xs-12 usas-extra-bottom-margin'><b>A disabled checkbox and a 'Club is Renewing' message below indicate that the club has opted to handle renewing the membership themselves. For these memberships, no further action is needed at this time. Please contact your club with any questions.
        </b></div>
      </div>
      <span className={style.UsasLabel}>USA Swimming Membership</span> &nbsp; &nbsp; &nbsp;  &nbsp;
      <span className={global.Italics}>Select Memberships</span>
      <table className={[global.UsasTable, 'visible-md visible-lg', 'usas-extra-bottom-margin'].join(' ')}>
        <thead>
          <tr>
            <th>Select</th>
            <th className={style.NameWidth}>Membership Name</th>
            <th>Expiration Date</th>
            <th style={{ minWidth: '130px' }}></th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {state?.isArrayLoaded !== true
            ? (<tr>
              {state?.isArrayLoading === true ?
                <td colSpan="5">Loading...</td> :
                <td colSpan="5">No USA Swimming Memberships with this Club are Currently Available</td>}
            </tr>)
            : Array.isArray(state?.arrayData) === true
              && state?.arrayData.length > 0
              ? state?.arrayData.map((offering, i) => {
                const foundIndex = selectedOfferingTypeIds.indexOf(offering.offeringInstanceId);
                return (
                  <Fragment key={i}>
                    <tr key={"info" + i} className={restrictions?.find(x => x.offeringId === offering.offeringId) || offering.clubBulkRenewalOptIn === true ? style.IsSelected : style.NotSelected}>
                      <td>
                        <Checkbox
                          name="offeringInstanceId"
                          onChange={(checked) => onChanged(checked, offering, offering.offeringInstanceId, offering.offeringId)}
                          checked={foundIndex >= 0 ? true : false}
                          disabled={restrictions?.find(x => x.offeringId === offering.offeringId) || offering.clubBulkRenewalOptIn === true ? true : false} />
                      </td>
                      <td className={style.NameWidth}><b>{offering.offeringName}</b></td>
                      <td>{formatDate(offering.expirationDate)}</td>
                      <td>
                        {offering.clubBulkRenewalOptIn === true ? "Club is Renewing" : ""}
                      </td>
                      <td className='pull-right'>
                        {offering?.offeringDescription &&
                          <button className={global.IconButtonMargin}
                            type="button"
                            onClick={(e) => onDetailsToggle(e, offering.offeringInstanceId)}>
                            <VisuallyHidden>{expandedId === offering.offeringInstanceId ? 'Hide' : 'Show'}Detail for{offering.offeringName}</VisuallyHidden>
                            <span className={style.MembershipDetails}>Details</span>
                          </button>}
                      </td>
                    </tr>
                    {expandedId === offering.offeringInstanceId &&
                      <tr key={"detail" + i}>
                        <td colSpan={5}>
                          <div className={style.ShowDetailsLarge}>
                            {offering.offeringDescription}
                          </div>
                        </td>
                      </tr>}
                  </Fragment>)
              })
              : (
                <tr>
                  <td colSpan="5">No USA Swimming Memberships with this Club are Currently Available</td>
                </tr>
              )}
        </tbody>
      </table>
    </Fragment>
  );
}
export default LargeOfferingsGrid;