import React, { Fragment } from 'react';

import { formatDate } from '../../../../common/utils/DateFunctions';

const LargeMyFamilyRegistrationsGridRows = ({ state }) => {
  return (
    <Fragment>
      {Array.isArray(state?.arrayData?.registrations) === true
        && state?.arrayData?.registrations?.length > 0
        && state?.arrayData?.registrations?.map((offering, i) => {
          return (
            <Fragment key={i}>
              <tr key={"info" + i}>
                <th></th>
                <td><b>{offering.offeringName}</b></td>
                <td>{formatDate(offering.expirationDate)}</td>
                <td>{offering.parentOrgUnitName} {offering.parentOrgUnitCode ? `(${offering.parentOrgUnitCode})` : ''}</td>
                <td>{offering.orgUnitName} {offering.orgUnitCode ? `(${offering.orgUnitCode})` : ''}</td>
                <td>Current</td>
                <th></th>
                <th></th>
                <td className='pull-right'>
                </td>
              </tr>
            </Fragment>)
        })
      }
    </Fragment>
  );
}

export default LargeMyFamilyRegistrationsGridRows;