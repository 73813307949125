import React, { Fragment } from 'react';

import SmallMyFamilyRegistrationsGridRows from './SmallMyFamilyRegistrationsGridRows';

import ExclamationIcon from '../../../../common/components/icons/ExclamationIcon';
import Checkbox from '../../../../common/components/checkboxes/Checkbox';
import { formatDate } from '../../../../common/utils/DateFunctions';

import { VisuallyHidden } from '@reach/visually-hidden';

import global from '../../../../common/components/GlobalStyle.module.css';
import style from '../../Omr.module.css';

const GridRowIcons = ({ offering, restrictions, onChanged, isChecked, onDetailsToggle, expandedId }) => (
  <Fragment>
    <Checkbox
      label={offering.clubBulkRenewalOptIn === true ? "Club is Renewing" : ""}
      name="offeringInstanceId"
      onChange={(checked) => onChanged(checked, offering, offering.offeringInstanceId, offering.offeringId)}
      checked={isChecked}
      disabled={restrictions?.find(x => x.offeringId === offering.offeringId) || offering.clubBulkRenewalOptIn === true ? true : false} />
    {offering?.offeringDescription &&
      <button
        className={[global.IconButton, style.MarginTopDetails].join(' ')}
        type="button"
        onClick={(e) => onDetailsToggle(e, offering.offeringInstanceId)}>
        <VisuallyHidden>{expandedId === offering.offeringInstanceId ? 'Hide' : 'Show'}Detail for{offering.offeringName}</VisuallyHidden>
        <span className={style.MembershipDetails}>Details</span>
      </button>
    }
  </Fragment>
);

const GridRowContent = ({ offering, restrictions, expandedId, lsc, club }) => (
  <div className={global.SmallTableRowInfo}>
    <div className={['row', (restrictions?.find(x => x.offeringId === offering.offeringId) || offering.clubBulkRenewalOptIn === true ? style.IsSelected : style.NotSelected)].join(' ')}>
      <div className={['col-xs-6 col-sm-3 visible-xs', global.SmallTableRowLabels].join(' ')}>
        Membership Name
      </div>
      <div className={['col-xs-6 col-sm-3 visible-xs', global.SmallTableRowData].join(' ')}>
        <span className={offering.isTransferOffering === true ? style.WarningMsg : ""}>{offering.offeringName}</span>
      </div>
      <div className={['col-xs-6 col-sm-3', global.SmallTableRowLabels].join(' ')}>
        LSC
      </div>
      <div className={['col-xs-6 col-sm-3', global.SmallTableRowData].join(' ')}>
        <span className={offering.isTransferOffering === true ? style.WarningMsg : ""}>{lsc || <span>&nbsp;</span>}</span>
      </div>
      <div className={['col-xs-6 col-sm-3', global.SmallTableRowLabels].join(' ')}>
        Club
      </div>
      <div className={['col-xs-6 col-sm-3', global.SmallTableRowData].join(' ')}>
        <span className={offering.isTransferOffering === true ? style.WarningMsg : ""}>{club || <span>&nbsp;</span>}</span>
      </div>
      <div className={['col-xs-6 col-sm-3', global.SmallTableRowLabels].join(' ')}>
        Expiration Date
      </div>
      <div className={['col-xs-6 col-sm-3', global.SmallTableRowData].join(' ')}>
        <span className={offering.isTransferOffering === true ? style.WarningMsg : ""}>{formatDate(offering.expirationDate)}</span>
      </div>
      <div className={['col-xs-6 col-sm-3', global.SmallTableRowLabels].join(' ')}>
        Membership Type
      </div>
      <div className={['col-xs-6 col-sm-3', global.SmallTableRowData].join(' ')}>
        <span className={offering.isTransferOffering === true ? style.WarningMsg : ""}>{offering.isUpgradeOffering === true ? 'Upgrade' : offering.isRenewalOffering === true ? 'Renewal' : 'New Role'}</span>
      </div>
      <div className={[global.SmallTableRowLabels, 'col-xs-6 col-sm-3'].join(' ')}>Transfer?</div>
      <div className={[global.SmallTableRowData, 'col-xs-6 col-sm-3'].join(' ')}>{offering.isTransferOffering === true ? <span><b><span style={{ color: '#BA0C2F' }}><ExclamationIcon toolTipText='Warning! Your renewal membership club is different than your prior membership club. By renewing with this link you are transferring this membership to a different club.' />&nbsp;Yes</span></b></span> : 'No'}</div>
      {expandedId === offering.offeringInstanceId &&
        <div>
          <p className={style.ShowDetailsSmall}>
            {offering.offeringDescription}
          </p>
        </div>}
    </div>
  </div>
);

const GridRow = ({ offering, restrictions, onChanged, isChecked, showDetails, onDetailsToggle, expandedId, lsc, club }) => {
  return (
    <Fragment>
      <div className={global.SmallTableRow}>
        <div className={global.SmallTableRowHead}>
          <span className='hidden-xs'>{offering.offeringName}</span>&nbsp;
          <GridRowIcons
            id={offering.offeringInstanceId}
            offering={offering}
            restrictions={restrictions}
            onChanged={onChanged}
            isChecked={isChecked}
            showDetails={showDetails}
            onDetailsToggle={onDetailsToggle}
            expandedId={expandedId} />
        </div>
        <GridRowContent offering={offering} restrictions={restrictions} showDetails={showDetails} expandedId={expandedId} lsc={lsc} club={club} />
      </div>
    </Fragment>
  );
}

const SmallMyFamilyOfferingsGrid = ({ state, selectedOfferingTypeIds, restrictions, onChanged, showDetails, onDetailsToggle, expandedId, lsc, club }) => (
  <div className={[global.SmallTable, 'hidden-md hidden-lg'].join(' ')}>
    <SmallMyFamilyRegistrationsGridRows state={state} />
    {state?.isArrayLoaded !== true
      ? (<div className={global.SmallTableRow}>
        <div className={global.SmallTableRowHead}>
          &nbsp;
        </div>
        <div className={global.SmallTableRowInfo}>
          {state?.isArrayLoading === true
            ? <div className={global.SmallTableRowLabels}>Loading... </div>
            : <div className={global.SmallTableRowLabels}>No USA Swimming Memberships with this Club are Currently Available to Add / Upgrade</div>}
        </div>
      </div>)
      : Array.isArray(state?.arrayData?.offeringTypeRegistrations) === true
        && state?.arrayData?.offeringTypeRegistrations.length > 0
        ? state?.arrayData?.offeringTypeRegistrations.map((offering, i) => {
          const foundIndex = selectedOfferingTypeIds.indexOf(offering.offeringInstanceId);
          return (
            <GridRow
              key={i}
              offering={offering}
              restrictions={restrictions}
              onChanged={onChanged}
              isChecked={foundIndex >= 0}
              showDetails={showDetails}
              onDetailsToggle={onDetailsToggle}
              expandedId={expandedId}
              lsc={lsc}
              club={club}
            />)
        })
        : (<div className={global.SmallTableRow}>
          <div className={global.SmallTableRowHead}>
            &nbsp;
          </div>
          <div className={global.SmallTableRowInfo}>
            <div className={global.SmallTableRowLabels}>No USA Swimming Memberships with this Club are Currently Available to Add / Upgrade</div>
          </div>
        </div>)
    }
  </div>
);

export default SmallMyFamilyOfferingsGrid;