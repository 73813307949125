import global from '../../../common/components/GlobalStyle.module.css';
import ActionIntraPageButton from '../../../common/components/buttons/ActionIntraPageButton';

import { formatDate } from '../../../common/utils/DateFunctions';

const MyFamilyMembersSmallGrid = ({ gridData, isLoading, onSelectClicked, onShowRenewalConfirmationModalClicked }) => (
  <div className={[global.SmallTable, 'hidden-md hidden-lg'].join(' ')}>
    {isLoading === true
      ? (
        <div className={global.SmallTableRow}>
          <div className={global.SmallTableRowHead}>
            &nbsp;
          </div>
          <div className={global.SmallTableRowInfo}>
            <div className={global.SmallTableRowLabels}>
              Loading...
            </div>
          </div>
        </div>
      ) : gridData.length > 0
        ? gridData.map((member, i) => (
          <div className={global.SmallTableRow} key={'personId' + member.personId + 'offeringInstanceId' + member.offeringInstanceId}>
            <div className={global.SmallTableRowHead}>
              <div style={{ maxWidth: '300px' }}>
                {member.provideAdditionalInformation === true ?
                  <ActionIntraPageButton
                    onClick={(e) => onShowRenewalConfirmationModalClicked(e, member)}>
                    Update Information & Register
                  </ActionIntraPageButton> :
                  <ActionIntraPageButton
                    onClick={(e) => onSelectClicked(e, member)}>
                    Add / Upgrade Memberships
                  </ActionIntraPageButton>}
              </div>
            </div>
            <div className={global.SmallTableRowInfo}>
              <div className='row'>
                <div className={[global.SmallTableRowLabels, 'col-xs-6'].join(' ')}>Name</div>
                <div className={[global.SmallTableRowData, 'col-xs-6'].join(' ')}>{(member.firstName || member.middleName || member.lastName) ? `${member.firstName || ''}  ${member.preferredName !== '' && member.preferredName !== member.firstName ? '"' + member.preferredName + '"' : ''} ${member.middleName || ''} ${member.lastName || ''}` : <span>&nbsp;</span>}</div>
                <div className={['col-xs-6', global.SmallTableRowLabels].join(' ')}>Comp. Category</div>
                <div className={[global.SmallTableRowData, 'col-xs-6'].join(' ')}>{member.competitionGenderTypeName || <span>&nbsp;</span>}</div>
                <div className={[global.SmallTableRowLabels, 'col-xs-6'].join(' ')}>Birth Date</div>
                <div className={[global.SmallTableRowData, 'col-xs-6'].join(' ')}>{member.birthDate ? formatDate(member.birthDate) : <span>&nbsp;</span>}</div>
              </div>
            </div>
          </div>
        )) : (
          <div className={global.SmallTableRow}>
            <div className={global.SmallTableRowHead}>
              &nbsp;
            </div>
            <div className={global.SmallTableRowInfo}>
              <div className={global.SmallTableRowLabels}>No Family Members are Associated with this Account</div>
            </div>
          </div>
        )
    }
  </div >
);

export default MyFamilyMembersSmallGrid;