import { PersonHttpHelper, NO_DATA_MESSAGE } from '../../../../common/utils/HttpHelper';
import { isValidMemberId } from '../../../../common/utils/validation';

export const localValidate = (formState) => {
    let errors = {};

    const memberFoundInChildArrayObject = formState.childArray.find(x => x.memberId === formState.memberId);

    //MemberId
    if (formState.memberId.trim() === '') {
        errors.memberId = 'Member Id is required';
    } else if (!isValidMemberId(formState.memberId.trim())) {
        errors.memberId = 'Member Id must be 14 alphanumeric characters';
    } else if (formState?.memberId === formState?.loggedInUserMemberId) {
        errors.memberId = 'Cannot add self as a child to parent account';
    } else if (memberFoundInChildArrayObject !== undefined && Object?.keys(memberFoundInChildArrayObject)?.length > 0) {
        errors.memberId = 'This member is already a child on this account, cannot add twice';
    };

    return errors;
};

const serverValidate = async (formState) => {
    let errors = {};

    //Check that the member Id can be added to this user
    const memberIdForUrl = formState.memberId.trim() ? encodeURIComponent(formState.memberId.trim()) : 'NaN';
    await PersonHttpHelper(`/PersonParentChild/Member/${memberIdForUrl}/Status`, 'GET')
        .then((objData) => {
            if (!objData) {
                throw new Error(NO_DATA_MESSAGE);
            }
            else {
                // if (objData?.hasParentAssociated === true) {
                //     errors.memberId = "Unable to add the person associated with this Member Id to 'My Family'. This person is already linked to a family.";
                // }
                if (objData?.addToFamilyAvailable === false) {
                    errors.memberId = "Unable to add the person associated with this Member Id to 'My Family'. This person does not have you listed as a contact. You must be listed as a contact in order to be able to add this person to your family. Please contact your club or LSC in order to update this person's contact information."
                }
                else {
                    if (objData?.newMemberId) {
                        formState.newMemberId = objData.newMemberId;
                    };
                }
            }
        })
        .catch(() => {
            errors.memberId = 'Member Id is not valid';
        });

    if (!errors.memberId) {
        //Check that the member Id has required acknowledgements
        const newMemberIdForUrl = formState.newMemberId ? formState.newMemberId : formState.memberId;
        await PersonHttpHelper(`/PersonAcknowledgement/Member/${newMemberIdForUrl}/Forms`, 'GET')
            .then((arrayData) => {
                if (!arrayData) {
                    throw new Error(NO_DATA_MESSAGE);
                }
                else {
                    if (arrayData?.length > 0) {
                        errors.acknowledgementsRequired = true;
                    }
                }
            })
            .catch(() => {
            });
    }

    return errors;
};

const MyFamilyValidation = async (formState) => {
    let errors = localValidate(formState);
    if (!errors || Object.keys(errors).length === 0) {
        errors = await serverValidate(formState);
    }

    return errors ? errors : {};
};

export default MyFamilyValidation;