import React, { Fragment } from 'react';

import { formatDate } from '../../../../common/utils/DateFunctions';

import global from '../../../../common/components/GlobalStyle.module.css';

const GridRowContent = ({ offering }) => (
  <div className={global.SmallTableRowInfo}>
    <div className={'row'}>
      <div className={['col-xs-6 col-sm-3 visible-xs', global.SmallTableRowLabels].join(' ')}>
        Membership Name
      </div>
      <div className={['col-xs-6 col-sm-3 visible-xs', global.SmallTableRowData].join(' ')}>
        {offering.offeringName}
      </div>
      <div className={['col-xs-6 col-sm-3', global.SmallTableRowLabels].join(' ')}>
        LSC
      </div>
      <div className={['col-xs-6 col-sm-3', global.SmallTableRowData].join(' ')}>
        {offering.parentOrgUnitName} {offering.parentOrgUnitCode ? `(${offering.parentOrgUnitCode})` : <span>&nbsp;</span>}
      </div>
      <div className={['col-xs-6 col-sm-3', global.SmallTableRowLabels].join(' ')}>
        Club
      </div>
      <div className={['col-xs-6 col-sm-3', global.SmallTableRowData].join(' ')}>
        {offering.orgUnitName} {offering.orgUnitCode ? `(${offering.orgUnitCode})` : <span>&nbsp;</span>}
      </div>
      <div className={['col-xs-6 col-sm-3', global.SmallTableRowLabels].join(' ')}>
        Expiration Date
      </div>
      <div className={['col-xs-6 col-sm-3', global.SmallTableRowData].join(' ')}>
        {formatDate(offering.expirationDate)}
      </div>
      <div className={['col-xs-6 col-sm-3', global.SmallTableRowLabels].join(' ')}>
        Membership Type
      </div>
      <div className={['col-xs-6 col-sm-3', global.SmallTableRowData].join(' ')}>
        Current
      </div>
    </div>
  </div>
);

const GridRow = ({ offering }) => {
  return (
    <Fragment>
      <div className={global.SmallTableRow}>
        <div className={global.SmallTableRowHead}>
          <span className='hidden-xs'>{offering.offeringName}</span>&nbsp;
        </div>
        <GridRowContent offering={offering} />
      </div>
    </Fragment>
  );
}

const SmallMyFamilyRegistrationsGridRows = ({ state }) => (
  <Fragment>
    {Array.isArray(state?.arrayData?.registrations) === true
      && state?.arrayData?.registrations?.length > 0
      && state?.arrayData?.registrations?.map((offering, i) => {
        return (
          <GridRow
            key={i}
            offering={offering}
          />)
      }
      )
    }
  </Fragment>
);

export default SmallMyFamilyRegistrationsGridRows;