import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from '../../../common/wrappers/ReactRouterDom';

import Constants from '../../../common/utils/Constants';

import useOmrData from '../../state/omr/UseOmrData';
import useOmrCookieData from '../../state/omrCookie/UseOmrCookieData';
import useOmrBackButtonData from '../../state/omrBackButton/UseOmrBackButtonData';

import useSecurityData from '../../../common/state/security/UseSecurityData';
import useDidMountEffect from '../../../common/utils/UseDidMountEffect';
import useRouteCodeValueData from '../../../common/state/routeCode/UseRouteCodeValueData';
import usePersonByMemberIdData from '../../../common/state/personByMemberId/UsePersonByMemberIdData';
import useNavRoutes from '../../../common/state/security/UseNavRoutes';
import usePersonGeneralData from '../../../common/state/personGeneral/UsePersonGeneralData';

const INITIAL_STATE = {
  isMembershipForLoggedInUser: null,
  tryContinueToAboutMe: false,
  newPersonStateObj: {}
};

const useEntry = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { navRoutes } = useNavRoutes();
  const { personState, getNewPersonStateObject, savePersonState, getInitializedStateObject, saveOmrState, isSaved } = useOmrData();
  const { omrCookieState } = useOmrCookieData;
  const { userInfo } = useSecurityData();
  const [state, setState] = useState(INITIAL_STATE);
  const { routeCodeValueState } = useRouteCodeValueData();
  const { personByMemberIdState, getPersonByMemberId } = usePersonByMemberIdData();
  const { personGeneralState, getPersonGeneral } = usePersonGeneralData();
  const { omrBackButtonState, setOmrBackButtonState } = useOmrBackButtonData();

  const onChildButtonClicked = (e) => {
    if (e?.preventDefault) {
      e.preventDefault();
    }

    const newPerson = getNewPersonStateObject();
    newPerson.person.offeringInstanceCouponId = routeCodeValueState?.offeringInstanceCouponId || Constants.DEFAULT_ID;

    savePersonState({
      ...newPerson,
      isMembershipForLoggedInUser: false,
      isExistingPerson: false,
      person: {
        ...newPerson.person
      }
    });

    setState({
      ...state,
      isMembershipForLoggedInUser: false,
      newPersonStateObj: {
        ...newPerson,
        isMembershipForLoggedInUser: false,
        isExistingPerson: false,
        person: {
          ...newPerson.person
        }
      }
    });
  }

  const onMyselfButtonClicked = (e) => {
    if (e?.preventDefault) {
      e.preventDefault();
    }

    const newPerson = getNewPersonStateObject();
    const offeringInstanceCouponId = routeCodeValueState?.offeringInstanceCouponId || Constants.DEFAULT_ID;
    const contactInfo = getSelfContact();
    const primaryContactInfo = getPrimaryContact();
    const personContacts = [];

    const newPersonStateObj = {
      ...newPerson,
      isMembershipForLoggedInUser: !location.state ? true : location.state?.membership?.memberId === userInfo.memberId ? true : false,
      isExistingPerson: true,
      person: {
        ...personState?.person,
        offeringInstanceCouponId,
        personId: personByMemberIdState.objData.personId,
        memberId: personByMemberIdState.objData.memberId,
        firstName: personByMemberIdState.objData.firstName,
        lastName: personByMemberIdState.objData.lastName,
        birthDate: personByMemberIdState.objData.birthDate,
        middleName: personByMemberIdState.objData.middleName,
        suffix: personByMemberIdState.objData.usasPerson?.suffix,
        preferredName: personByMemberIdState.objData.preferredName,
        isUsCitizen: personByMemberIdState.objData.usasPerson?.isUsCitizen,
        hispanicOrLatinoTypeId: personByMemberIdState.objData.usasPerson?.hispanicOrLatinoTypeId,
        personEthnicity: personByMemberIdState.objData.usasPerson?.personEthnicity ? [...personByMemberIdState.objData.usasPerson?.personEthnicity] : [],
        competitionGenderTypeId: personByMemberIdState.objData.competitionGenderType?.competitionGenderTypeId,
        competitionGenderTypeName: personByMemberIdState.objData.competitionGenderType?.typeName,
        contactInfo,
        primaryContactInfo,
        personContacts
      }
    }
    savePersonState(newPersonStateObj);

    setState({
      ...state,
      isMembershipForLoggedInUser: !location.state ? true : location.state?.membership?.memberId === userInfo.memberId ? true : false,
      newPersonStateObj: newPersonStateObj
    });
  };

  const getSelfContact = () => {
    let selfContact = {
      emailAddress: '',
      phoneNumber: '',
      phoneTypeId: Constants.DEFAULT_ID,
      address: ''
    };
    const contacts = personGeneralState.objData?.personContact || [];
    contacts.forEach(contact => {
      if (contact?.contactRelationshipType?.typeName === 'Self') {
        let address = {};
        if (Array.isArray(contact?.personContactAddress) && contact.personContactAddress.length > 0) {
          address = { ...(contact.personContactAddress[0].address || {}) };
        }
        let phone = {};
        if (Array.isArray(contact?.personContactPhone) && contact.personContactPhone.length > 0) {
          phone = { ...contact.personContactPhone[0] };
        }
        selfContact = {
          emailAddress: contact.emailAddress || '',
          phoneNumber: phone?.phoneNumber || '',
          phoneTypeId: phone?.phoneType?.phoneTypeId || Constants.DEFAULT_ID,
          address
        };
      }
    });

    return selfContact;
  };

  const getPrimaryContact = () => {
    let primaryContact = {
      firstName: '',
      lastName: '',
      contactRelationshipTypeId: Constants.DEFAULT_ID,
      contactRelationshipName: '',
      emailAddress: '',
      phoneNumber: '',
      phoneTypeId: Constants.DEFAULT_ID,
      address: '',
      isPrimaryContact: true
    };
    const contacts = personGeneralState.objData?.personContact || [];
    contacts.forEach(contact => {
      if (contact?.isPrimary === true) {
        let address = {};
        if (Array.isArray(contact?.personContactAddress) && contact.personContactAddress.length > 0) {
          address = { ...(contact.personContactAddress[0]?.address || {}) };
        }
        let phone = {};
        if (Array.isArray(contact?.personContactPhone) && contact.personContactPhone.length > 0) {
          phone = { ...contact.personContactPhone[0] };
        }
        primaryContact = {
          firstName: contact.firstName || '',
          lastName: contact.lastName || '',
          contactRelationshipTypeId: contact?.contactRelationshipType?.contactRelationshipTypeId || Constants.DEFAULT_ID,
          contactRelationshipName: contact?.contactRelationshipType?.typeName || '',
          emailAddress: contact.emailAddress || '',
          phoneNumber: phone?.phoneNumber || '',
          phoneTypeId: phone?.phoneType?.phoneTypeId || Constants.DEFAULT_ID,
          address,
          isPrimaryContact: true
        };
      }
    });

    return primaryContact;
  };

  const onBackToWelcomePageClicked = (e) => {
    if (e?.preventDefault) {
      e.preventDefault();
    }
    document.getElementById('omrHeadingId')?.scrollIntoView();
    if (routeCodeValueState.routeCodeValue) {
      navigate(navRoutes.WELCOME.route + '/' + routeCodeValueState.routeCodeValue);
    }
    else {
      navigate(navRoutes.WELCOME.route);
    }
  };

  // do this after the save state completes
  useDidMountEffect(() => {
    if (isSaved === true && Object.keys(state.newPersonStateObj).length > 0) {
      if (state.isMembershipForLoggedInUser === true || state.isMembershipForLoggedInUser === false) {
        document.getElementById('omrHeadingId')?.scrollIntoView();
        navigate(navRoutes.ABOUT_MEMBER.route);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSaved, state.isMembershipForLoggedInUser, state.newPersonStateObj]);

  useEffect(() => {
    if (location.state && location.state?.membership) {
      document.getElementById('omrHeadingId')?.scrollIntoView();
      if (personByMemberIdState.isObjLoaded !== true && location.state.membership.memberId) {
        const newOmrState = getInitializedStateObject(0, routeCodeValueState.orgUnitId, omrCookieState?.objData?.vendorToken || '', routeCodeValueState.routeCodeValue);
        saveOmrState(newOmrState);
        setOmrBackButtonState({ ...omrBackButtonState, navigateToWelcomePage: true });
        getPersonByMemberId(location.state.membership.memberId);
        setState({ ...state, tryContinueToAboutMe: true })
      }
    }
    else {
      if (personByMemberIdState.isObjLoaded !== true && userInfo.memberId) {
        getPersonByMemberId(userInfo.memberId);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [personByMemberIdState, userInfo.memberId, location.state]);

  useEffect(() => {
    if (personGeneralState.isObjLoaded !== true && personByMemberIdState.objData?.personId) {
      getPersonGeneral(personByMemberIdState.objData.personId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [personGeneralState, personByMemberIdState]);

  useEffect(() => {
    if (personGeneralState.isObjLoaded === true && personByMemberIdState.objData?.personId &&
      state.tryContinueToAboutMe === true) {
      onMyselfButtonClicked();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [personGeneralState, personByMemberIdState, state.tryContinueToAboutMe]);

  return {
    isLoading: personByMemberIdState?.isObjLoading === true || personGeneralState?.isObjLoading === true || (location.state && location.state?.membership),
    onChildButtonClicked,
    onMyselfButtonClicked,
    routeCodeValueState,
    onBackToWelcomePageClicked
  };
};

export default useEntry;