import { useEffect, useState } from 'react';

import validateMyFamily from './MyFamilyValidation';
import validateSaveContactToMyFamily from './SaveContactToMyFamilyValidation';

import useLandingPageData from '../../../state/landingPages/UseLandingPageData';
import usePersonParentChildData from '../../../state/landingPages/personParentChild/UsePersonParentChildData';
import usePersonData from '../../../state/landingPages/person/UsePersonData';
import useChildrenData from '../../../state/landingPages/children/UseChildrenData';

import usePersonAcknowledgementInfoData from '../../../../common/state/personAcknowledgementInfo/UsePersonAcknowledgementInfoData';
import usePersonAcknowledgementAddAFamilyMemberData from '../../../../common/state/personAcknowledgementAddAFamilyMember/UsePersonAcknowledgementAddAFamilyMemberData';
import usePersonParentChildFamilyContactData from '../../../../common/state/personParentChildFamilyContact/UsePersonParentChildFamilyContactData';

import useForm from '../../../../common/utils/UseForm';
import useOmrData from '../../../state/omr/UseOmrData';
import useRouteCodeValueData from '../../../../common/state/routeCode/UseRouteCodeValueData';
import useOmrCookieData from '../../../state/omrCookie/UseOmrCookieData';

const useMyFamily = () => {
  const { landingPageState } = useLandingPageData();
  const { personParentChildState, postPersonParentChildByMemberId, confirmSave } = usePersonParentChildData();
  const { personParentChildFamilyContactState, postPersonParentChildFamilyContactsByPersonId, resetPersonParentChildFamilyContactState } = usePersonParentChildFamilyContactData();
  const { personState } = usePersonData();
  const { childrenState, getChildrenAfterFamilyMemberChanges } = useChildrenData();
  const { saveOmrState, getInitializedStateObject } = useOmrData();
  const { routeCodeValueState } = useRouteCodeValueData();
  const { omrCookieState } = useOmrCookieData();
  const [uiState, setUiState] = useState({ addClicked: false, tryCloseWindow: false });
  const [acknowledgementsModalState, setAcknowledgementsModalState] = useState({ modalTitle: 'Complete the Following Legal Acknowledgements for the Member You Wish to Add to Your Family', displayPopUp: false });
  const { formState, setFormState, errorState, onFormValueChanged, setErrors, isSubmitting }
    = useForm(getInitialFormState());
  const { resetPersonAcknowledgementInfoPageViewState, setPersonAcknowledgementInfoPageViewState, personAcknowledgementInfoPageViewState } = usePersonAcknowledgementInfoData();
  const { personAcknowledgementAddAFamilyMemberState, resetPersonAcknowledgementsAddAFamilyMemberState } = usePersonAcknowledgementAddAFamilyMemberData();
  const setLoggedInUserMemberId = () => {
    if (personState?.isObjLoaded === true) {
      setFormState({
        ...formState,
        loggedInUserMemberId: personState?.objData.memberId,
        childArray: landingPageState?.currentPerson?.children
      });
    }
  }

  const onAddFamilyMemberClicked = () => {
    setUiState({ ...uiState, addClicked: true });
    setLoggedInUserMemberId();
  };

  const onSaveFamilyMemberClicked = (e) => {
    if (e && e.preventDefault) {
      e.preventDefault();
    }
    tryValidateMyFamilyBeforeSave();
  };

  const tryValidateMyFamilyBeforeSave = async () => {
    const errors = await validateMyFamily(formState) || {};
    setErrors(errors);
    if (Object.keys(errors).length === 0) {
      if (formState.newMemberId.trim()) {
        postPersonParentChildByMemberId(formState.newMemberId.trim());
        onAddFamilyMemberModalCanceled();
      }
      else if (formState.memberId.trim()) {
        postPersonParentChildByMemberId(formState.memberId.trim());
        onAddFamilyMemberModalCanceled();
      }
    }
  };

  const onSaveContactToFamilyClicked = (e) => {
    if (e && e.preventDefault) {
      e.preventDefault();
    }
    tryValidateSaveContactToMyFamilyBeforeSave();
  };

  const tryValidateSaveContactToMyFamilyBeforeSave = async () => {
    const errors = await validateSaveContactToMyFamily(formState) || {};
    setErrors(errors);
    if (Object.keys(errors).length === 0) {
      const familyContactsArray = [];
      for (const contact of personParentChildFamilyContactState.arrayData) {
        const index = formState.userContact.findIndex(x => x.id === contact.personId)
        if (index >= 0) {
          familyContactsArray.push(contact);
        }
      }
      postPersonParentChildFamilyContactsByPersonId(personState.objData.personId, familyContactsArray);
      setUiState({ ...uiState, addClicked: false, tryCloseWindow: true });
    }
  };

  const onAddFamilyMemberModalCanceled = (e) => {
    if (e && e.preventDefault) {
      e.preventDefault();
    }

    setUiState({ ...uiState, addClicked: false, tryCloseWindow: false });
    setFormState(getInitialFormState());
    setErrors({});
  };

  const onAcknowledgmentsModalCanceled = (e) => {
    if (e && e.preventDefault) {
      e.preventDefault();
    }

    setAcknowledgementsModalState({ ...acknowledgementsModalState, displayPopUp: false });
    resetPersonAcknowledgementInfoPageViewState();
  };

  const onContinueClicked = (e) => {
    e?.preventDefault();
    //straight to omr left view
    document.getElementById('omrHeadingId')?.scrollIntoView();
    const newOmrState = getInitializedStateObject(0, routeCodeValueState.orgUnitId, omrCookieState?.objData?.vendorToken || '', routeCodeValueState.routeCodeValue);
    saveOmrState(newOmrState);
  };

  useEffect(() => {
    if (errorState.acknowledgementsRequired === true) {
      setAcknowledgementsModalState({ ...acknowledgementsModalState, displayPopUp: true });
      setPersonAcknowledgementInfoPageViewState({ ...personAcknowledgementInfoPageViewState, selectedPerson: {}, showConfirmContactInfoView: false, showChangeContactInfoView: false, showAcknowledgeSwitchesView: true })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [errorState])

  useEffect(() => {
    if (personAcknowledgementAddAFamilyMemberState.isSaved === true) {
      resetPersonAcknowledgementsAddAFamilyMemberState();
      onAcknowledgmentsModalCanceled();
      if (formState.newMemberId.trim()) {
        postPersonParentChildByMemberId(formState.newMemberId.trim());
      } else if (formState.memberId.trim()) {
        postPersonParentChildByMemberId(formState.memberId.trim());
      }
      onAddFamilyMemberModalCanceled();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [personAcknowledgementAddAFamilyMemberState.isSaved])

  useEffect(() => {
    if (personState?.objData?.personId && (personParentChildState.isSaved === true ||
      (personParentChildFamilyContactState.isArrayLoaded === true && uiState.tryCloseWindow === true))) {
      confirmSave();
      resetPersonParentChildFamilyContactState();
      getChildrenAfterFamilyMemberChanges(personState?.objData?.personId);
      window.location.reload();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [personState?.objData?.personId, personParentChildState.isSaved, personParentChildFamilyContactState.isArrayLoaded, uiState.tryCloseWindow]);

  function submitFormCallback() {
    if (formState.newMemberId.trim()) {
      postPersonParentChildByMemberId(formState.newMemberId.trim());
      onAddFamilyMemberModalCanceled();
    }
    else if (formState.memberId.trim()) {
      postPersonParentChildByMemberId(formState.memberId.trim());
      onAddFamilyMemberModalCanceled();
    }
  };

  function getInitialFormState() {
    return {
      memberId: '',
      newMemberId: '',
      loggedInUserMemberId: '',
      childArray: [],
      userContact: []
    };
  };

  return {
    children: landingPageState?.currentPerson?.children || [],
    isLoading: landingPageState?.stateStatus === 'Loading' || personParentChildState.isObjLoading
      || childrenState.isArrayLoading,
    isSaving: personParentChildState.isSaving || (personParentChildFamilyContactState.isArrayLoading && uiState.tryCloseWindow === true),
    isSubmitting: isSubmitting,
    uiState,
    formState,
    errorState,
    onFormValueChanged,
    onAddFamilyMemberClicked,
    onSaveFamilyMemberClicked,
    onSaveContactToFamilyClicked,
    onAddFamilyMemberModalCanceled,
    landingPageState,
    acknowledgementsModalState,
    personState,
    onAcknowledgmentsModalCanceled,
    onContinueClicked
  };
};

export default useMyFamily;