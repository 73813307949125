import { Fragment } from 'react';

import Input from '../../../common/components/inputs/Input';
import InternationalPhoneInput from '../../../common/components/inputs/InternationalPhoneInput';
import PhoneTypeDropdown from '../../../common/components/dropdowns/phoneTypeDropdown/PhoneTypeDropdown';
import StatesCombobox from '../../../common/components/comboboxes/statesCombobox/StatesCombobox';
import CountryCombobox from '../../../common/components/comboboxes/countryCombobox/CountryCombobox';
import AddressValidation from '../../../common/components/addressValidation/AddressValidation';

const RegistrantContactInfoForm = ({ formState, errorState, onFormValueChanged, onValueTextPairChanged, setFormData,
  onCountryChanged, isCountryUSA, isCountryMexico, isCountryCanada }) => (
  <Fragment>
    <div className="row usas-extra-bottom-margin">
      <div className="col-xs-12 col-sm-6">
        <Input
          label="Email Address*"
          name="email"
          value={formState.email}
          error={errorState.email}
          message={errorState.email}
          onChange={(value) => { onFormValueChanged('email', value) }} />
      </div>
      <div className="col-xs-12 col-sm-4">
        <InternationalPhoneInput
          label="Phone Number*"
          name="phoneNumber"
          value={formState.phoneNumber}
          onChange={(value) => onFormValueChanged('phoneNumber', value)}
          error={errorState.phoneNumber}
          message={errorState.phoneNumber} />
      </div>
      <div className="col-xs-12 col-sm-2">
        <PhoneTypeDropdown
          label="Phone Type*"
          name="phoneTypeId"
          value={formState.phoneTypeId}
          error={errorState.phoneTypeId}
          message={errorState.phoneTypeId}
          onChange={(newValue, newValueLabel, e) => {
            e && e.target && e.target.value
              && onValueTextPairChanged(newValue, 'phoneTypeId', newValueLabel, 'phoneTypeName');
          }} />
      </div>
    </div>
    <div className="row usas-extra-top-margin usas-extra-bottom-margin">
      <div className="col-xs-12 col-sm-6">
        <Input
          label="Address Line 1*"
          name="address1"
          value={formState.address1}
          error={errorState.address1}
          message={errorState.address1}
          onChange={(value) => { onFormValueChanged('address1', value) }} />
      </div>
      <div className="col-xs-12 col-sm-6 ">
        <Input
          label="Address Line 2"
          name="address2"
          value={formState.address2}
          error={errorState.address2}
          message={errorState.address2}
          onChange={(value) => { onFormValueChanged('address2', value) }} />
      </div>
    </div>
    <div className='row usas-extra-top-margin'>
      <div className="col-xs-12 col-sm-6">
        <Input
          label="City*"
          name="city"
          value={formState.city}
          error={errorState.city}
          message={errorState.city}
          onChange={(value) => { onFormValueChanged('city', value) }} />
      </div>
      <div className="col-xs-12 col-sm-6">
        <CountryCombobox
          label="Country*"
          name="countryCode"
          valueToMatch={formState.countryName}
          error={errorState.countryCode}
          message={errorState.countryCode}
          onChange={(newValue, newValueLabel) => { onCountryChanged(newValue, newValueLabel) }} />
      </div>
      {(isCountryUSA === true || isCountryMexico === true || isCountryCanada === true) &&
        <div className="col-xs-12 col-sm-6">
          <StatesCombobox
            label="State*"
            name="stateCode"
            valueToMatch={formState.stateName}
            error={errorState.stateCode}
            message={errorState.stateCode}
            onChange={(newValue, newValueLabel, e) => { onValueTextPairChanged(newValue, 'stateCode', newValueLabel, 'stateName', e); }}
            isUSA={isCountryUSA === true}
            isMexico={isCountryMexico === true}
            isCanada={isCountryCanada === true} />
        </div>}
      <div className="col-xs-12 col-sm-3">
        <Input
          label="Zip Code*"
          name="postalCode"
          value={formState.postalCode}
          error={errorState.postalCode}
          message={errorState.postalCode}
          onChange={(value) => { onFormValueChanged('postalCode', value) }} />
      </div>
      <AddressValidation formState={formState} setFormData={setFormData} />
    </div>
  </Fragment>
);

export default RegistrantContactInfoForm;