import React, { Fragment } from 'react';

import useMyFamilyWelcomeMessage from './UseMyFamilyWelcomeMessage';

import ErrorHandle from '../components/ErrorHandle';
import MyFamily from './myFamily/MyFamily';
import MyFamilyMembersGrid from './MyFamilyMembersGrid';
import MyFamilyOfferingsGrid from '../membershipTypes/grids/MyFamilyOfferingsGrid';

import CompetitionCategoryRadiobuttonList from '../../../common/components/radiobuttonlists/competitionCategoryRadiobuttonList/CompetitionCategoryRadiobuttonList';
import LoadingModal from '../../../common/components/dialogs/LoadingModal';
import Headings from '../../../common/components/headings/Headings';
import PopUpModal from '../../../common/components/dialogs/PopUpModal';
import SecondaryButton from '../../../common/components/buttons/SecondaryButton';
import PrimaryButton from '../../../common/components/buttons/PrimaryButton';

import Constants from '../../../common/utils/Constants';
import { formatDate } from '../../../common/utils/DateFunctions';

import style from '../Omr.module.css';

const MyFamilyWelcomeMessage = () => {
  const { isLoading, lsc, club, lscContact, memberRegistrationState, offeringModalState, onOfferingModalCanceled, onSelectClicked,
    renewModalState, onShowRenewalConfirmationModalClicked, onRenewConfirmationClicked, onRenewConfirmationModalCanceled,
    onAddNewMembershipClicked, showNoMembershipsAvailable, memberRegistrationOfferingsState, onAllMembershipCheck, state, restrictions,
    newRoleConfirmationModalState, onNewRoleConfirmationModalCanceled, onNewRoleConfirmationContinueClicked,
    competitionGenderTypeSelectionModalState, setCompetitionGenderTypeSelectionModalFormState,
    competitionGenderTypeSelectionModalFormState, competitionGenderTypeSelectionModalErrorState,
    onCompetitionGenderTypeSelectionContinueClicked, onCompetitionGenderTypeSelectionModalCanceled } = useMyFamilyWelcomeMessage();

  if (isLoading === true) {
    return <LoadingModal />;
  }

  return (
    <Fragment>
      <div className={['row usas-extra-top-margin', style.BottomMargin].join('')}>
        <div className={[style.CenterAlign, style.TextPadding, 'usas-extra-top-margin'].join(' ')}>
          All registrations made on this page will be associated with
        </div>
        <div className='row usas-extra-top-margin' />
        {lsc !== null
          ? <Fragment>
            <div className={['col-xs-12', style.CenterAlign, style.TextPadding].join(' ')}>
              <span className={style.WelcomeHeader}>LSC: </span> &nbsp;
              <span className={style.WelcomeHeaderData}>{lsc}</span>
            </div>
          </Fragment>
          : <Fragment />}
      </div>
      <div className='row'>
        <div className={['col-xs-12', style.CenterAlign, style.TextPadding].join(' ')}>
          <span className={style.WelcomeHeader}>Club: </span> &nbsp;
          <span className={style.WelcomeHeaderData}>{club}</span>
        </div>
      </div>
      {lscContact &&
        <div className='row'>
          <div className={['col-xs-12', style.CenterAlign, style.TextPadding].join(' ')}>
            <span className={style.WelcomeHeader}>LSC Contact: </span> &nbsp;
            <span className={style.WelcomeHeaderData}>{lscContact}</span>
          </div>
        </div>}
      <div className='row'>
        <div className='col-xs-12 usas-extra-top-margin usas-extra-bottom-margin' />
        <div className={[style.CenterAlign, style.TextPadding, 'usas-extra-bottom-margin'].join(' ')}>
          If you don't want to register with this club, please contact the club you intend to register with to get the correct link.
        </div>
      </div>
      <div className="row usas-extra-top-margin">
        <div className="col-xs-12 usas-extra-top-margin">
          <MyFamily centerAlignButtons={true} memberRegistrationStateArrayData={memberRegistrationState.arrayData} />
        </div>
        {memberRegistrationState.arrayData?.length > 0 &&
          <div className="col-xs-12">
            <MyFamilyMembersGrid
              gridData={memberRegistrationState.arrayData}
              isLoading={memberRegistrationState.isArrayLoading}
              onSelectClicked={onSelectClicked}
              onShowRenewalConfirmationModalClicked={onShowRenewalConfirmationModalClicked}
            />
          </div>}
      </div>
      <PopUpModal
        widthPct={90}
        maxWidth={720}
        title={renewModalState?.modalTitle}
        displayPopUp={renewModalState?.displayPopUp}
        onModalCanceled={onRenewConfirmationModalCanceled}>
        <div className="row">
          <div className={["col-xs-12 usas-extra-bottom-margin", style.CenterAlign].join(' ')}>
            <p><b>Selected Family Member:</b> {(renewModalState?.membership?.firstName || renewModalState?.membership?.middleName || renewModalState?.membership?.lastName) ? `${renewModalState?.membership?.firstName || ''}  ${renewModalState?.membership?.preferredName !== '' && renewModalState?.membership?.preferredName !== renewModalState?.membership?.firstName ? '"' + renewModalState?.membership?.preferredName + '"' : ''} ${renewModalState?.membership?.middleName || ''} ${renewModalState?.membership?.lastName || ''}` : ''} ({renewModalState?.membership?.competitionGenderTypeName || ''}, DOB: {renewModalState?.membership?.birthDate ? formatDate(renewModalState?.membership?.birthDate) : ''})</p>
            <p>&nbsp;</p>
            <p>Registration will be associated with</p>
            <p>LSC: <b>{lsc || ''}</b></p>
            <p>Club: <b>{club || ''}</b></p>
            <p>If you don't want to register with this club, please contact the club you intend to register with to get the correct link.</p>
          </div>
        </div>
        <div className="row usas-extra-top-margin">
          <div className="col-xs-12 usas-extra-top-margin">
            <PrimaryButton type="button" onClick={onRenewConfirmationClicked}>Yes, Continue</PrimaryButton>&nbsp;
            <SecondaryButton type="button" onClick={onRenewConfirmationModalCanceled}>No, Cancel</SecondaryButton>
          </div>
        </div>
      </PopUpModal>
      <PopUpModal
        widthPct={90}
        maxWidth={1400}
        title={offeringModalState.modalTitle + ` with ${lsc} - ${club}`}
        displayPopUp={offeringModalState.displayPopUp && memberRegistrationState?.isArrayLoading === false && memberRegistrationOfferingsState.isArrayLoading === false}
        onModalCanceled={onOfferingModalCanceled}>
        <div className="row">
          <div className="col-xs-12">
            <span><b>Selected Family Member:</b> {(offeringModalState?.member?.firstName || offeringModalState?.member?.middleName || offeringModalState?.member?.lastName) ? `${offeringModalState?.member?.firstName || ''}  ${offeringModalState?.member?.preferredName !== '' && offeringModalState?.member?.preferredName !== offeringModalState?.member?.firstName ? '"' + offeringModalState?.member?.preferredName + '"' : ''} ${offeringModalState?.member?.middleName || ''} ${offeringModalState?.member?.lastName || ''}` : ''}</span>
          </div>
        </div>
        <div className="row usas-extra-bottom-margin" />
        <div className='row'>
          <div className='col-xs-12 usas-extra-top-margin'>
            {showNoMembershipsAvailable === true ?
              <Fragment>
                <div className="col-xs-12">
                  <p className={style.CenterAlign}>There are currently no memberships available for this registrant, please check back later.</p>
                </div>
              </Fragment>
              :
              <Fragment>
                <MyFamilyOfferingsGrid
                  state={memberRegistrationOfferingsState}
                  selectedOfferingTypeIds={state.selectedOfferingTypeIds}
                  onChanged={onAllMembershipCheck}
                  restrictions={restrictions}
                  lsc={lsc}
                  club={club} />
                <ErrorHandle error={state.errorMessage} message={state.errorMessage} />
              </Fragment>
            }
          </div>
        </div>
        <div className="row usas-extra-top-margin">
          <div className="col-xs-12 usas-extra-top-margin">
            <PrimaryButton type="button" onClick={onAddNewMembershipClicked}>Save</PrimaryButton>&nbsp;
            <SecondaryButton type="button" onClick={onOfferingModalCanceled}>Cancel</SecondaryButton>
          </div>
        </div>
      </PopUpModal>
      <PopUpModal
        widthPct={90}
        maxWidth={720}
        title={competitionGenderTypeSelectionModalState.modalTitle}
        displayPopUp={competitionGenderTypeSelectionModalState.displayPopUp}
        onModalCanceled={onCompetitionGenderTypeSelectionModalCanceled}>
        <div className="row">
          <div className="col-xs-12 usas-extra-bottom-margin">
            <span><b>Selected Family Member:</b> {(offeringModalState?.member?.firstName || offeringModalState?.member?.middleName || offeringModalState?.member?.lastName) ? `${offeringModalState?.member?.firstName || ''}  ${offeringModalState?.member?.preferredName !== '' && offeringModalState?.member?.preferredName !== offeringModalState?.member?.firstName ? '"' + offeringModalState?.member?.preferredName + '"' : ''} ${offeringModalState?.member?.middleName || ''} ${offeringModalState?.member?.lastName || ''}` : ''}</span>
          </div>
        </div>
        <form>
          <div className="row">
            <div className="col-xs-12 usas-extra-top-margin usas-extra-bottom-margin">
              <CompetitionCategoryRadiobuttonList
                label={<Headings.H6>Select Competition Category</Headings.H6>}
                isVertical={true}
                name="competitionGenderTypeId"
                value={Number(competitionGenderTypeSelectionModalFormState.competitionGenderTypeId)}
                error={competitionGenderTypeSelectionModalErrorState.competitionGenderTypeId}
                message={competitionGenderTypeSelectionModalErrorState.competitionGenderTypeId}
                onChange={(value) => { setCompetitionGenderTypeSelectionModalFormState({ ...competitionGenderTypeSelectionModalFormState, competitionGenderTypeId: Number(value) }) }} />
            </div>
            <div className="col-xs-12 usas-extra-top-margin">
              <PrimaryButton type="button" onClick={onCompetitionGenderTypeSelectionContinueClicked}>Save</PrimaryButton>&nbsp;
              <SecondaryButton type="button" onClick={onCompetitionGenderTypeSelectionModalCanceled}>Cancel</SecondaryButton>
            </div>
          </div>
        </form>
      </PopUpModal>
      <PopUpModal
        widthPct={90}
        maxWidth={1400}
        title={newRoleConfirmationModalState.modalTitle}
        displayPopUp={newRoleConfirmationModalState.displayPopUp}
        onModalCanceled={onNewRoleConfirmationModalCanceled}>
        <form>
          <div className="row">
            <div className="col-xs-12">
              <b>Your selected memberships have been added to the shopping cart!</b>
            </div>
            <div className="col-xs-12 usas-extra-top-margin usas-extra-bottom-margin">
              <span>Please choose between continuing to the shopping cart to complete your membership purchases, or staying on this page and starting the process to manage more memberships.</span>
            </div>
            <div className="col-xs-12 usas-extra-top-margin">
              <PrimaryButton type="button" onClick={onNewRoleConfirmationContinueClicked}>Continue to Shopping Cart</PrimaryButton>&nbsp;
              <SecondaryButton type="button" onClick={onNewRoleConfirmationModalCanceled}>Manage More Memberships</SecondaryButton>
            </div>
          </div>
        </form>
      </PopUpModal>
      <PopUpModal
        widthPct={50}
        maxWidth={250}
        title={Constants.LOADING_MSG}
        displayPopUp={memberRegistrationState?.isArrayLoading || memberRegistrationOfferingsState?.isArrayLoading} />
    </Fragment>
  );
};

export default MyFamilyWelcomeMessage;