import React, { Fragment } from 'react';

import useMembershipTypes from '../UseMembershipTypes';

import LargeMyFamilyOfferingsGrid from './LargeMyFamilyOfferingsGrid';
import SmallMyFamilyOfferingsGrid from './SmallMyFamilyOfferingsGrid';

const MyFamilyOfferingsGrid = ({ state, selectedOfferingTypeIds, restrictions, onChanged, lsc, club }) => {
  const { onDetailsToggle, expandedId } = useMembershipTypes();

  return (
    <Fragment>
      <LargeMyFamilyOfferingsGrid
        state={state}
        selectedOfferingTypeIds={selectedOfferingTypeIds}
        restrictions={restrictions}
        onChanged={onChanged}
        onDetailsToggle={onDetailsToggle}
        expandedId={expandedId}
        lsc={lsc}
        club={club} />
      <SmallMyFamilyOfferingsGrid
        state={state}
        selectedOfferingTypeIds={selectedOfferingTypeIds}
        restrictions={restrictions}
        onChanged={onChanged}
        onDetailsToggle={onDetailsToggle}
        expandedId={expandedId}
        lsc={lsc}
        club={club} />
    </Fragment>
  )
};

export default MyFamilyOfferingsGrid;