import { useEffect, useState } from 'react';
import { useNavigate, useLocation } from '../../../../common/wrappers/ReactRouterDom';

import validate from '../PrimaryContactInfoValidation';
import NavLinks from '../../../NavLinks';
import useOmrData from '../../../state/omr/UseOmrData';

import usePhoneTypeData from '../../../../common/state/phoneType/UsePhoneTypeData';
import useContactRelationshipData from '../../../../common/state/contactRelationshipType/UseContactRelationshipTypesData';
import useLeftNavModalData from '../../../../common/state/leftNavModal/UseLeftNavModalData';
import useForm from '../../../../common/utils/UseForm';
import Constants, { COUNTRY_CODE_CANADA, COUNTRY_CODE_MEXICO, COUNTRY_CODE_UNITED_STATES } from '../../../../common/utils/Constants';
import useDidMountEffect from '../../../../common/utils/UseDidMountEffect';
import useCountryCombobox from '../../../../common/components/comboboxes/countryCombobox/UseCountryCombobox';
import useStatesCombobox from '../../../../common/components/comboboxes/statesCombobox/UseStatesCombobox';

const INITIAL_FORM_STATE = {
  firstName: '',
  lastName: '',
  contactRelationshipTypeId: Constants.DEFAULT_ID,
  contactRelationshipName: '',
  email: '',
  phoneNumber: '',
  phoneTypeId: Constants.DEFAULT_ID,
  address1: '',
  address2: '',
  city: '',
  stateCode: '',
  stateName: '',
  countryCode: '',
  countryName: '',
  postalCode: '',
  isCountryUSA: false,
  isCountryMexico: false,
  isCountryCanada: false,
  validatedAddress: null,
  showedAddressSuggestion: false
};

const INITIAL_VALIDATE_STATE = {
  isValidating: false,
  validatedAddress: null
};

const usePrimaryContactO15Self = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { personState, savePersonState, isSaved } = useOmrData();
  const { contactRelationshipTypeState } = useContactRelationshipData();
  const { leftNavModalState, setLeftNavModalState } = useLeftNavModalData();
  const { phoneTypeState } = usePhoneTypeData();
  const [state, setState] = useState({ hasAdditionalContactButtonBeenClick: false });
  const [validateState, setValidateState] = useState(INITIAL_VALIDATE_STATE);
  const {
    formState,
    errorState,
    onFormValueChanged,
    onValueTextPairChanged,
    setFormData,
    setFormState,
    handleSubmit,
    updateFormState,
    setIsDirty
  } = useForm(INITIAL_FORM_STATE, submitFormCallback, validate);
  const { getStateNameById } = useStatesCombobox();
  const { countryData } = useCountryCombobox();

  const handleSubmitProxy = async (e) => {
    if (e && e.preventDefault) {
      e.preventDefault();
    }
    setValidateState({
      isValidating: true
    });
    await handleSubmit();
    setValidateState({
      isValidating: false,
      validatedAddress: formState.validatedAddress
    });
    updateFormState('showedAddressSuggestion', true);
  };

  const onCountryChanged = (countryCode, countryName) => {
    let newFormState = {
      ...formState,
      countryCode,
      countryName,
      isCountryUSA: false,
      isCountryMexico: false,
      isCountryCanada: false,
      stateCode: '',
      stateName: ''
    };

    if (countryCode === COUNTRY_CODE_UNITED_STATES) {
      newFormState = {
        ...newFormState,
        isCountryUSA: true,
      };
    } else if (countryCode === COUNTRY_CODE_MEXICO) {
      newFormState = {
        ...newFormState,
        isCountryMexico: true,
      };
    } else if (countryCode === COUNTRY_CODE_CANADA) {
      newFormState = {
        ...newFormState,
        isCountryCanada: true,
      };
    }

    setFormState(newFormState);
    setIsDirty(true);
  }

  function submitFormCallback(formState) {
    const newPrimaryContactInfo = {
      firstName: formState.firstName?.trim(),
      lastName: formState.lastName?.trim(),
      contactRelationshipTypeId: formState.contactRelationshipTypeId,
      contactRelationshipName: formState.contactRelationshipName,
      emailAddress: formState.email?.trim(),
      phoneNumber: formState.phoneNumber?.trim(),
      phoneTypeId: formState.phoneTypeId,
      address: {
        address1: formState.address1?.trim(),
        address2: formState.address2?.trim(),
        city: formState.city?.trim(),
        stateCode: formState.stateCode,
        stateName: formState.stateName,
        postalCode: formState.postalCode?.trim(),
        countryCode: formState.countryCode
      },
      isPrimaryContact: true
    };

    savePersonState({
      ...personState,
      person: {
        ...personState.person,
        primaryContactInfo: newPrimaryContactInfo
      }
    });
  };

  const onActionIntraPageButtonClicked = (e) => {
    if (e?.preventDefault) {
      e.preventDefault();
    }

    handleSubmit();
    setState({
      ...state,
      hasAdditionalContactButtonBeenClick: true
    });
  }

  useEffect(() => {
    if (location?.state) {
      if (Object.keys(location?.state?.personContactObj).length > 0) {
        const contactRelationshipTypeId = Array.isArray(contactRelationshipTypeState.arrayData) && contactRelationshipTypeState.arrayData.length > 0
          ? contactRelationshipTypeState.arrayData[0].contactRelationshipTypeId
          : Constants.DEFAULT_ID;

        const contactRelationshipName = Array.isArray(contactRelationshipTypeState.arrayData) && contactRelationshipTypeState.arrayData.length > 0
          ? contactRelationshipTypeState.arrayData[0].typeName
          : ' ';

        setFormState({
          ...formState,
          firstName: location?.state?.personContactObj?.firstName,
          lastName: location?.state?.personContactObj?.lastName,
          contactRelationshipTypeId,
          contactRelationshipName,
          email: location?.state?.personContactObj?.email,
          phoneNumber: location?.state?.personContactObj?.phoneNumber,
          phoneTypeId: location?.state?.personContactObj?.phoneTypeId,
          address1: location?.state?.personContactObj?.address1,
          address2: location?.state?.personContactObj?.address2,
          city: location?.state?.personContactObj?.city,
          stateName: location?.state?.personContactObj?.stateCode ? getStateNameById(location?.state?.personContactObj?.stateCode) : '',
          stateCode: location?.state?.personContactObj?.stateCode,
          postalCode: location?.state?.personContactObj?.postalCode,
          countryCode: location?.state?.personContactObj?.countryCode || COUNTRY_CODE_UNITED_STATES,
          countryName: location?.state?.personContactObj?.countryCode ? countryData?.find(x => x.id === location?.state?.personContactObj?.countryCode)?.name : countryData?.find(x => x.id === COUNTRY_CODE_UNITED_STATES)?.name,
          isCountryUSA: location?.state?.personContactObj?.countryCode ? location?.state?.personContactObj?.countryCode === COUNTRY_CODE_UNITED_STATES : true,
          isCountryMexico: location?.state?.personContactObj?.countryCode === COUNTRY_CODE_MEXICO,
          isCountryCanada: location?.state?.personContactObj?.countryCode === COUNTRY_CODE_CANADA,
          isPrimaryContact: true,
        });
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location?.state]);

  useEffect(() => {
    if (personState && Object.keys(personState).length > 0) {
      if (personState.person?.primaryContactInfo?.firstName !== '' && personState.person?.primaryContactInfo?.lastName !== '') {
        let formStateCopy = [];
        if (formState.firstName !== '' && isSaved === false) {
          formStateCopy = JSON.parse(JSON.stringify(formState));
          if ((formStateCopy.firstName !== personState.person?.primaryContactInfo?.firstName) ||
            (formStateCopy.lastName !== personState.person?.primaryContactInfo?.lastName) ||
            (formStateCopy.contactRelationshipTypeId !== personState.person?.primaryContactInfo?.contactRelationshipTypeId) ||
            (formStateCopy.contactRelationshipName !== personState.person?.primaryContactInfo?.contactRelationshipName) ||
            (formStateCopy.email !== personState.person?.primaryContactInfo?.emailAddress) ||
            (formStateCopy.phoneNumber !== personState.person?.primaryContactInfo?.phoneNumber) ||
            (formStateCopy.phoneTypeId !== personState.person?.primaryContactInfo?.phoneTypeId) ||
            (formStateCopy.address1 !== personState.person?.primaryContactInfo?.address?.address1) ||
            (formStateCopy.address2 !== personState.person?.primaryContactInfo?.address?.address2) ||
            (formStateCopy.city !== personState.person?.primaryContactInfo?.address?.city) ||
            (formStateCopy.stateCode !== personState.person?.primaryContactInfo?.address?.stateCode) ||
            (formStateCopy.postalCode !== personState.person?.primaryContactInfo?.address?.postalCode) ||
            (formStateCopy.countryCode !== personState.person?.primaryContactInfo?.address?.countryCode)) {

            setLeftNavModalState({ ...leftNavModalState, formStateSaved: false });
          }
        }
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formState, personState]);


  useEffect(() => {
    let formStateCopy = [];
    if (isSaved === false
      && (personState.person?.primaryContactInfo?.firstName === '' && personState.person?.primaryContactInfo?.lastName === '')) {
      formStateCopy = JSON.parse(JSON.stringify(formState));
      if ((formStateCopy.firstName === '') ||
        (formStateCopy.lastName === '') ||
        (formStateCopy.contactRelationshipTypeId === '') ||
        (formStateCopy.contactRelationshipName === '') ||
        (formStateCopy.email === '') ||
        (formStateCopy.phoneNumber === '') ||
        (formStateCopy.phoneTypeId === Constants.DEFAULT_ID) ||
        (formStateCopy.address1 === '') ||
        (formStateCopy.address2 === '') ||
        (formStateCopy.city === '') ||
        (formStateCopy.stateCode === Constants.DEFAULT_ID) ||
        (formStateCopy.stateName === '') ||
        (formStateCopy.postalCode === '') ||
        (formStateCopy.countryCode === '') ||
        (formStateCopy.countryName === '')) {

        setLeftNavModalState({ ...leftNavModalState, formStateSaved: false });
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formState, personState.person, isSaved]);


  useEffect(() => {
    if (!formState.firstName && personState.person) {
      const primaryContactInfo = personState.person?.primaryContactInfo || {};

      const firstContactRelationshipTypeId = Array.isArray(contactRelationshipTypeState.arrayData) && contactRelationshipTypeState.arrayData.length > 0
        ? contactRelationshipTypeState.arrayData[0].contactRelationshipTypeId
        : Constants.DEFAULT_ID;

      const contactRelationshipTypeId = primaryContactInfo?.contactRelationshipTypeId > 0
        ? primaryContactInfo.contactRelationshipTypeId
        : firstContactRelationshipTypeId;

      const contactRelationshipName = primaryContactInfo?.contactRelationshipName === ''
        ? contactRelationshipTypeState.arrayData[0].typeName
        : primaryContactInfo.contactRelationshipName;

      const firstPhoneTypeId = Array.isArray(phoneTypeState?.arrayData) && phoneTypeState?.arrayData.length > 0
        ? phoneTypeState.arrayData[0].phoneTypeId
        : Constants.DEFAULT_ID;

      const phoneTypeId = primaryContactInfo?.phoneTypeId > 0 ? primaryContactInfo?.phoneTypeId : firstPhoneTypeId;

      setFormData({
        ...formState,
        firstName: primaryContactInfo.firstName || '',
        lastName: primaryContactInfo.lastName || '',
        contactRelationshipTypeId,
        contactRelationshipName,
        email: primaryContactInfo.emailAddress || '',
        phoneNumber: primaryContactInfo.phoneNumber || '',
        phoneTypeId,
        address1: primaryContactInfo.address?.address1 || '',
        address2: primaryContactInfo.address?.address2 || '',
        city: primaryContactInfo.address?.city || '',
        stateCode: primaryContactInfo.address?.stateCode || '',
        stateName: primaryContactInfo.address?.stateCode ? getStateNameById(primaryContactInfo.address?.stateCode) : '',
        postalCode: primaryContactInfo.address?.postalCode || '',
        countryCode: primaryContactInfo.address?.countryCode || COUNTRY_CODE_UNITED_STATES,
        countryName: primaryContactInfo?.address?.countryCode ? countryData?.find(x => x.id === primaryContactInfo?.address?.countryCode)?.name : countryData?.find(x => x.id === COUNTRY_CODE_UNITED_STATES)?.name,
        isCountryUSA: primaryContactInfo.address?.countryCode ? primaryContactInfo.address?.countryCode === COUNTRY_CODE_UNITED_STATES : true,
        isCountryMexico: primaryContactInfo.address?.countryCode === COUNTRY_CODE_MEXICO,
        isCountryCanada: primaryContactInfo.address?.countryCode === COUNTRY_CODE_CANADA,
      }, true);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [personState]);

  // do this after the save state completes
  useDidMountEffect(() => {
    if (isSaved === true) {

      setLeftNavModalState({ ...leftNavModalState, formStateSaved: true });
      document.getElementById('omrHeadingId')?.scrollIntoView();
      if (state.hasAdditionalContactButtonBeenClick === false) {

        navigate(NavLinks.MEMBERSHIP_16_AND_UP);
      } else {

        navigate(NavLinks.ADDITIONAL_CONTACTS_O15_SELF);
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSaved]);

  return {
    formState,
    errorState,
    onFormValueChanged,
    onValueTextPairChanged,
    onCountryChanged,
    setFormData,
    onNextButtonClicked: handleSubmitProxy,
    validateState,
    onActionIntraPageButtonClicked,
    isCountryUSA: formState.isCountryUSA === true,
    isCountryMexico: formState.isCountryMexico === true,
    isCountryCanada: formState.isCountryCanada === true
  };
};

export default usePrimaryContactO15Self;
