import React from 'react';

import useWelcome from './UseWelcome';

import MyFamilyWelcomeMessage from './MyFamilyWelcomeMessage';
import NotLoggedInMessage from './NotLoggedInMessage';
import ExpiredOmrLink from './ExpiredOmrLink';

import Headings from '../../../common/components/headings/Headings';

import style from '../Omr.module.css';

const Welcome = () => {
  const { isLoggedIn, isRouteCodeValueZero, routeCodeValueState } = useWelcome();

  return (
    <div className='row'>
      <div className="col-xs-12" id="omrHeadingId">
        <Headings.H2 excludeBar={true} className={style.CenterAlign}>USA Swimming Online Member Registration{routeCodeValueState.offeringInstanceCouponId && ' - Outreach'}</Headings.H2>
        <hr className="usas-bar-turmeric" />
       <Headings.H3 excludeBar={true} className={style.CenterAlign}>Manage Registrations for My Family</Headings.H3>
        {isLoggedIn === true
          ? isLoggedIn === true && isRouteCodeValueZero === false
            ?
            <MyFamilyWelcomeMessage />
            : <ExpiredOmrLink />
          : <NotLoggedInMessage />}
      </div>
    </div>
  );
};

export default Welcome;