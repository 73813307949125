import { useContext, useEffect, useState } from 'react';
import convertOmrStateToApiPostableObj from './ConvertOmrStateToApiPostableObj';
import hasAthleteOffering from './HasAthleteOffering';
import OmrData from './OmrData';
import { OmrStateContext } from './OmrContextProvider';

import { BASIC_INITIAL_STATE } from '../../../common/utils/HttpHelper';

const useOmrData = () => {
  const [omrState, setOmrState] = useContext(OmrStateContext);
  const [postDataState, setPostDataState] = useState({ ...BASIC_INITIAL_STATE });
  const [summaryViewState, setSummaryViewState] = useState({ ...BASIC_INITIAL_STATE });

  const getInitializedStateObject = (loggedInPersonId, orgUnitId, vendorToken, routeCode) => {
    return {
      ...OmrData.INITIAL_STATE,
      loggedInPersonId,
      orgUnitId,
      currentIndex: 0,
      persons: [getNewPersonStateObject()],
      vendorToken,
      routeCode
    };
  };

  const getNewPersonStateObject = () => {
    return JSON.parse(JSON.stringify(OmrData.PERSON_INITIAL_STATE));
  };

  const saveOmrState = (newOmrState, saved = true) => {
    const newState = { ...newOmrState, isSaved: saved === false ? false : true };
    setOmrState(newState);
  };

  const savePersonState = (newPersonState, saved) => {
    const newPersonsArray = omrState.persons.map((person, i) => {
      if (i === omrState.currentIndex) {
        const newPerson = JSON.parse(JSON.stringify(newPersonState));
        return { ...newPerson };
      } else {
        return { ...person };
      }
    });

    saveOmrState({
      ...omrState,
      persons: [...newPersonsArray]
    }, saved);
  };

  useEffect(() => {
    if (omrState.action || omrState.isSaved === true) {
      setOmrState({ ...omrState, action: '', isSaved: false });
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const postOmrData = () => {
    const apiPostableObj = convertOmrStateToApiPostableObj(omrState);
    OmrData.postNewMemberRegistration(apiPostableObj, postDataState, setPostDataState);
  };

  const postMemberRegistrationSummary = () => {
    const apiPostableObj = convertOmrStateToApiPostableObj(omrState);
    OmrData.postMemberRegistrationSummary(apiPostableObj, summaryViewState, setSummaryViewState);
  };

  const resetForPost = () => {
    setPostDataState({
      ...postDataState,
      isObjLoaded: false,
      isSaving: false,
      isSaved: false,
      objData: {},
      message: ''
    });
  };

  return {
    getInitializedStateObject,
    getNewPersonStateObject,
    omrState,
    setOmrState,
    personState: omrState.persons[omrState.currentIndex],
    saveOmrState,
    savePersonState,
    resetOmrPerson: () => { },
    isSaved: omrState.isSaved,
    hasAthleteOffering,
    postOmrData,
    postDataState,
    resetForPost,
    postMemberRegistrationSummary,
    summaryViewState,
    setSummaryViewState
  };
};

export default useOmrData;