import { useContext } from 'react';

import MemberRegistrationOfferingsData from './MemberRegistrationOfferingsData';

import { MemberRegistrationOfferingsStateContext } from './MemberRegistrationOfferingsContextProvider';

const useMemberRegistrationOfferingsData = () => {
  const [memberRegistrationOfferingsState, setMemberRegistrationOfferingsState] = useContext(MemberRegistrationOfferingsStateContext);

  const getMemberRegistrationOfferings = (personId, orgUnitId, offeringInstanceCouponId) => {
    return MemberRegistrationOfferingsData.getMemberRegistrationOfferingsData(personId, orgUnitId, offeringInstanceCouponId, memberRegistrationOfferingsState, setMemberRegistrationOfferingsState);
  };

  const postMemberRegistrationOfferings = (personId, orgUnitId, routeCode, offeringInstanceCouponId, competitionGenderTypeId, offeringsArray) => {
    return MemberRegistrationOfferingsData.postMemberRegistrationOfferingsData(personId, orgUnitId, routeCode, offeringInstanceCouponId, competitionGenderTypeId, offeringsArray, memberRegistrationOfferingsState, setMemberRegistrationOfferingsState);
  };

  const resetMemberRegistrationOfferingsState = () => {
    setMemberRegistrationOfferingsState({
      ...MemberRegistrationOfferingsData.INITIAL_MEMBER_REGISTRATION_OFFERINGS_STATE
    });
  };

  return {
    memberRegistrationOfferingsState,
    setMemberRegistrationOfferingsState,
    getMemberRegistrationOfferings,
    postMemberRegistrationOfferings,
    resetMemberRegistrationOfferingsState
  };
};

export default useMemberRegistrationOfferingsData;