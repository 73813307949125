import React, { Fragment } from 'react';

import MyFamilyMembersLargeGrid from './MyFamilyMembersLargeGrid';
import MyFamilyMembersSmallGrid from './MyFamilyMembersSmallGrid';

const MyFamilyMembersGrid = ({ gridData, isLoading, onSelectClicked, onShowRenewalConfirmationModalClicked }) => {
  return (
    <Fragment>
      <MyFamilyMembersLargeGrid
        gridData={gridData}
        isLoading={isLoading}
        onSelectClicked={onSelectClicked}
        onShowRenewalConfirmationModalClicked={onShowRenewalConfirmationModalClicked}
      />
      <MyFamilyMembersSmallGrid
        gridData={gridData}
        isLoading={isLoading}
        onSelectClicked={onSelectClicked}
        onShowRenewalConfirmationModalClicked={onShowRenewalConfirmationModalClicked}
      />
    </Fragment>
  )
};

export default MyFamilyMembersGrid;