import React from 'react';

import useAdditionalContactO15SelfAddEditContact from './UseAdditionalContactO15SelfAddEditContact';
import AdditionalContactAddEditForm from '../AdditionalContactAddEditForm';

import Views from '../../../Views';
import NavLinks from '../../../NavLinks';
import ViewTemplate from '../../../components/viewTemplate/ViewTemplate';
import AddressBook from '../../../components/AddressBook/AddressBook';

import PopUpModal from '../../../../common/components/dialogs/PopUpModal';

const AdditionalContactO15SelfAddEditContact = () => {
  const {
    formState,
    errorState,
    onFormValueChanged,
    onValueTextPairChanged,
    onCountryChanged,
    setFormData,
    onNextButtonClicked,
    validateState,
    onBackButtonClicked,
    isCountryUSA,
    isCountryMexico,
    isCountryCanada
  } = useAdditionalContactO15SelfAddEditContact();

  return (
    <ViewTemplate
      viewName={Views.ADDITIONAL_CONTACTS_O15_SELF_ADD_EDIT_CONTACT}
      onBackButtonClicked={onBackButtonClicked}
      onNextButtonClicked={onNextButtonClicked}>
      {validateState.isValidating === true && (
        <PopUpModal
          displayPopUp={true}
          maxWidth={250}
          title="Validating..."
          widthPct={50} />
      )}
      <AddressBook navlink={NavLinks.ADDITIONAL_CONTACTS_O15_SELF_ADD_EDIT_CONTACT} />
      <AdditionalContactAddEditForm
        formState={formState}
        errorState={errorState}
        onFormValueChanged={onFormValueChanged}
        onValueTextPairChanged={onValueTextPairChanged} 
        setFormData={setFormData}
        onCountryChanged={onCountryChanged}
        isCountryUSA={isCountryUSA}
        isCountryMexico={isCountryMexico}
        isCountryCanada={isCountryCanada} />
    </ViewTemplate>
  );
};

export default AdditionalContactO15SelfAddEditContact;