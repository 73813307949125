import { createContext, useState } from 'react';

import MemberRegistrationOfferingsData from './MemberRegistrationOfferingsData';

export const MemberRegistrationOfferingsStateContext = createContext();

const MemberRegistrationOfferingsContextProvider = ({ children }) => {
  const stateHook = useState(MemberRegistrationOfferingsData.INITIAL_MEMBER_REGISTRATION_OFFERINGS_STATE);

  return (
    <MemberRegistrationOfferingsStateContext.Provider value={stateHook}>
      {children}
    </MemberRegistrationOfferingsStateContext.Provider>
  );
};

export default MemberRegistrationOfferingsContextProvider;