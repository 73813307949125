import { createContext, useState } from 'react';

import MemberRegistrationData from './MemberRegistrationData';

export const MemberRegistrationStateContext = createContext();

const MemberRegistrationContextProvider = ({ children }) => {
  const stateHook = useState(MemberRegistrationData.INITIAL_MEMBER_REGISTRATION_STATE);

  return (
    <MemberRegistrationStateContext.Provider value={stateHook}>
      {children}
    </MemberRegistrationStateContext.Provider>
  );
};

export default MemberRegistrationContextProvider;