import { useContext } from 'react';

import MemberRegistrationData from './MemberRegistrationData';

import { MemberRegistrationStateContext } from './MemberRegistrationContextProvider';

const useMemberRegistrationData = () => {
  const [memberRegistrationState, setMemberRegistrationState] = useContext(MemberRegistrationStateContext);

  const getMemberRegistration = (personId) => {
    return MemberRegistrationData.getMemberRegistrationData(personId, memberRegistrationState, setMemberRegistrationState);
  };

  const resetMemberRegistrationState = () => {
    setMemberRegistrationState({
      ...MemberRegistrationData.INITIAL_MEMBER_REGISTRATION_STATE
    });
  };

  return {
    memberRegistrationState,
    setMemberRegistrationState,
    getMemberRegistration,
    resetMemberRegistrationState
  };
};

export default useMemberRegistrationData;