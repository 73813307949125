export const localValidate = (formState) => {
    let errors = {};

    if (formState.userContact.length === 0) {
        errors.userContact = 'At least one Contact is required';
    }

    return errors;
};

const SaveContactToMyFamilyValidation = async (formState) => {
    let errors = localValidate(formState);

    return errors ? errors : {};
};

export default SaveContactToMyFamilyValidation;