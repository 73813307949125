import { API_NAMES, initApi } from '../../../common/utils/HttpApiHelper';
import { BASIC_INITIAL_STATE } from '../../../common/utils/HttpHelper';

const INITIAL_MEMBER_REGISTRATION_STATE = {
  ...BASIC_INITIAL_STATE
};

const getMemberRegistrationData = (personId, state, setState) => {
  if (personId) {
    const api = initApi(API_NAMES.OMR, state, setState);
    const personIdForUrl = encodeURIComponent(personId);
    const url = `/MemberRegistration/person/${personIdForUrl}`;

    return api?.executeArray ? api.executeArray(url, 'GET') : null;
  }
};

const MemberRegistrationData = {
  INITIAL_MEMBER_REGISTRATION_STATE,
  getMemberRegistrationData
};

export default MemberRegistrationData;